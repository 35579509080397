import React, { useEffect, useState } from "react";
import {
  faPhoneVolume,
  faKey,
  faDollarSign,
  faMoneyBillAlt,
  faInfo,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import WalletService from "../../../services/api/wallet.service";
import { setMessage } from "../../../redux/slices/message";
import LoadingSpinner from "../../../components/common/loader/LoadingSpinner";
import MessageBox from "../../../components/common/message/MessageBox";
import AlertDismissible from "../../../components/common/message/AlertDismissible";
import { setUpdatedCoinsChange } from "../../../redux/slices/common";
import SiteService from "../../../services/api/site.service";

const Withdraw = ({ setMessage, setUpdatedCoinsChange }) => {
  const [passwordMode, setPasswordMode] = useState({
    password: "password",
    confirmPassword: "password",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    "Your request has been created."
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [withdrawMin, setWithdrawMin] = useState(500);
  const [withdrawMax, setWithdrawMax] = useState(15000);
  const [withdrawPaymentTypeList, setWithdrawPaymentTypeList] = useState([
    {
      id: "Agent",
      name: "Agent",
    },
    {
      id: "Personal",
      name: "Personal",
    },
  ]);

  useEffect(() => {
    SiteService.getSiteSettings().then((res) => {
      console.log(res);
      const withdraw_min_limit = res.find((x) => x.key == "withdraw_min_limit");
      setWithdrawMin(withdraw_min_limit.value);
      const withdraw_max_limit = res.find((x) => x.key == "withdraw_max_limit");
      setWithdrawMax(withdraw_max_limit.value);
      console.log(withdraw_max_limit);
      // if (res.key.withdraw_min_limit) {
      // }
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const handlePasswordMode = (state) => {
    let passwordModeDummy = { ...passwordMode };
    if (passwordModeDummy[state] == "password") {
      passwordModeDummy[state] = "text";
    } else {
      passwordModeDummy[state] = "password";
    }
    setPasswordMode(passwordModeDummy);
  };

  const onSubmit = (data) => {
    // console.log(data);

    const formData = new FormData();
    formData.append(
      "withdraw_amount",
      data.withdraw_amount ? data.withdraw_amount : ""
    );
    formData.append("password", data.password ? data.password : "");
    formData.append("phone_no", data.phone_no ? data.phone_no : "");
    formData.append("payment_type", data.payment_type ? data.payment_type : "");
    setIsSubmitting(true);
    WalletService.withdraw(formData).then(
      (data) => {
        if (data) {
          // console.log('withdraw', data);
          setIsSubmitSuccess(true);
          setSuccessMessage(data?.message);
          setUpdatedCoinsChange(1000);
          // setMessage({
          //   type: "success",
          //   title: data?.message,
          // });
        }
        setTimeout(() => {
          setIsSubmitting(false);
        }, 2100);
        window.scrollTo({ top: 0, behavior: "smooth" });
        return Promise.resolve();
      },
      (error) => {
        // console.log(error?.response?.data?.errors?.message);
        // setMessage({
        //   type: "error",
        //   title: error?.response?.data?.errors?.message,
        // });
        setTimeout(() => {
          setErrorMessage(error?.response?.data?.errors?.message);
          setIsSubmitting(false);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 1000);
        return Promise.reject();
      }
    );
  };

  return (
    <>
      <div className="tab-pane" id="nav-withdraw" role="tabpanel">
        <div className="admin-content-header-new">Withdraw</div>
        <div className="admin-content-scroll-wrapper">
          {isSubmitSuccess ? (
            <MessageBox message={successMessage} />
          ) : (
            <>
              <div className="row admin-profile-update-form">
                <div className="col-lg-12">
                  <AlertDismissible
                    message={errorMessage}
                    setErrorMessage={setErrorMessage}
                  />
                  <form
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="admin-profile-condition rounded-2 fw-bold">
                          <span className="icon rounded-pill">
                            <FontAwesomeIcon icon={faInfo} />
                          </span>
                          <span className="text">
                            Minimum Withdrawal Amount{" "}
                            <strong>{withdrawMin}</strong> &amp; Maximum Amount{" "}
                            <strong>{withdrawMax}</strong>{" "}
                            <span className="colored-text">
                              Site Reserve Coin: 10
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="adf-withdraw-amount">
                            Withdraw Amount*
                          </label>
                          <div className="form-group-input-wrapper">
                            <span className="input-group-icon">
                              <FontAwesomeIcon icon={faDollarSign} />
                              <i className="fas fa-dollar-sign"></i>
                            </span>
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              id="adf-withdraw-amount"
                              placeholder="Withdraw Amount"
                              {...register("withdraw_amount", {
                                required: "Amount required",
                                min: {
                                  value: 0,
                                  message: "Amount must be greater than 0",
                                },
                              })}
                            />
                          </div>
                          {errors.withdraw_amount && (
                            <span className="input-validation">
                              {errors.withdraw_amount.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="adf-withdraw-phone-no">
                            Phone No*
                          </label>
                          <div className="form-group-input-wrapper">
                            <span className="input-group-icon">
                              <FontAwesomeIcon icon={faPhoneVolume} />
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              id="adf-withdraw-phone-no"
                              placeholder="Phone Number"
                              {...register("phone_no", {
                                required: "Phone required",
                                minLength: {
                                  value: 11,
                                  message: "Phone number must be 11 digit",
                                },
                                maxLength: {
                                  value: 11,
                                  message: "Phone number must be 11 digit",
                                },
                                pattern: {
                                  value: /^[0-9]+$/i,
                                  message: "Please enter a number",
                                },
                              })}
                            />
                          </div>
                          {errors.phone_no && (
                            <span className="input-validation">
                              {errors.phone_no.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="adf-withdraw-payment-type">
                            Payment Type*
                          </label>
                          {withdrawPaymentTypeList &&
                            withdrawPaymentTypeList.length > 0 && (
                              <div className="form-group-input-wrapper">
                                <span className="input-group-icon">
                                  <FontAwesomeIcon icon={faMoneyBillAlt} />
                                </span>
                                <select
                                  className="form-control"
                                  {...register("payment_type")}
                                  defaultValue={withdrawPaymentTypeList[0].id}
                                >
                                  {withdrawPaymentTypeList.map(
                                    (method, index) => {
                                      return (
                                        <option value={method.id} key={index}>
                                          {method.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="adf-current-password-for-club">
                            Current Password*
                          </label>
                          <div className="form-group-input-wrapper">
                            <span className="input-group-icon">
                              <FontAwesomeIcon icon={faKey} />
                            </span>
                            <input
                              className="form-control"
                              type={passwordMode.password}
                              placeholder="Current Password"
                              {...register("password", {
                                required: "Password required",
                              })}
                            />
                            <button
                              type="button"
                              className="field-icon toggle-password bg-transparent border-0 d-flex align-items-center p-0"
                              onClick={() => handlePasswordMode("password")}
                            >
                              <FontAwesomeIcon
                                icon={
                                  passwordMode.password == "password"
                                    ? faEye
                                    : faEyeSlash
                                }
                              />
                            </button>
                          </div>
                          {errors.password && (
                            <span
                              className="input-validation"
                              style={{ right: "40px" }}
                            >
                              {errors.password.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-8">
                        <button
                          type="submit"
                          value="Withdraw"
                          className="admin-profile-update-form-submit"
                          name="admin-withdraw"
                          disabled={isSubmitting}
                        >
                          Withdraw
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {isSubmitting && <LoadingSpinner overlay={true} />}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default connect(null, { setMessage, setUpdatedCoinsChange })(Withdraw);
