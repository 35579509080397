import React, { useEffect, useState } from 'react';
import { faPhoneVolume, faEye, faCoins, faKey, faInfo, faMoneyBillAlt, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WalletService from '../../../services/api/wallet.service';
import { setMessage } from '../../../redux/slices/message';
import MessageBox from '../../../components/common/message/MessageBox';
import LoadingSpinner from '../../../components/common/loader/LoadingSpinner';
import { setUpdatedCoinsChange } from '../../../redux/slices/common';
import AlertDismissible from '../../../components/common/message/AlertDismissible';

const MakeDeposit = ({ setMessage, setUpdatedCoinsChange }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("Your request has been created.");
  const [errorMessage, setErrorMessage] = useState("");
  const [phoneTo, setPhoneTo] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('Skrill');
  const [passwordMode, setPasswordMode] = useState({
    password: "password",
    confirmPassword: "password",
  });
  const [depositPaymentMethodList, setDepositPaymentMethodList] = useState([
    {
      id: 'Skrill',
      name: 'Skrill'
    },
    {
      id: 'Stripe',
      name: 'Stripe'
    },
    {
      id: 'Neteller',
      name: 'Neteller'
    }
  ]);

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    mode: 'onChange'
  });

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      WalletService.getDepositNumber().then(
        (data) => {
          if (data) {
            // console.log('getDepositNumber', data);
            let depositPaymentMethodListDummy = [...depositPaymentMethodList];
            depositPaymentMethodListDummy.push({ id: 'Bkash', name: 'Bkash' });
            setDepositPaymentMethodList(depositPaymentMethodListDummy);
            setSelectedPaymentMethod("Bkash");
            if (data.phone_no) {
              setPhoneTo(data.phone_no);
            }
          }
          return Promise.resolve();
        },
        (error) => {
          // console.log(error);
          return Promise.reject();
        }
      );
    }

    return () => {
      mounted = false;
    }
  }, []);

  const handleSelect = (e) => {
    // console.log(e.target.value);
    let id = e.target.value;
    setSelectedPaymentMethod(id);
  };

  const handlePasswordMode = (state) => {
    let passwordModeDummy = { ...passwordMode };
    if (passwordModeDummy[state] == "password") {
      passwordModeDummy[state] = "text";
    } else {
      passwordModeDummy[state] = "password";
    }
    setPasswordMode(passwordModeDummy);
  };

  const onSubmit = (data) => {
    // console.log(data);

    if (!phoneTo) {
      // setMessage({
      //   type: "error",
      //   title: "Deposit Not Available",
      // });
      setErrorMessage("Deposit Not Available");
      return false;
    }

    const formData = new FormData();
    formData.append('coin_number', data.coin_number ? data.coin_number : '');
    formData.append('password', data.password ? data.password : '');
    formData.append('phone_from', data.phone_from ? data.phone_from : '');
    formData.append('phone_to', phoneTo ? phoneTo : '');
    setIsSubmitting(true);
    WalletService.makeDeposit(formData).then(
      (data) => {
        if (data) {
          // console.log('makeDeposit', data);
          setIsSubmitSuccess(true);
          setSuccessMessage(data);
          setUpdatedCoinsChange(1000);
          // setMessage({
          //   type: "success",
          //   title: data,
          // });
          reset();
        }
        setTimeout(() => {
          setIsSubmitting(false);
        }, 2100);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return Promise.resolve();
      },
      (error) => {
        // console.log(error?.response?.data?.errors?.message);
        // setMessage({
        //   type: "error",
        //   title: error?.response?.data?.errors?.message,
        // });
        setTimeout(() => {
          setErrorMessage(error?.response?.data?.errors?.message);
          setIsSubmitting(false);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 1000);
        return Promise.reject();
      }
    );
  };

  return (<>
    <div className="tab-pane" id="nav-make-diposit" role="tabpanel">
      <div className="admin-content-header-new">Make Deposit</div>
      <div className="admin-content-scroll-wrapper">
        {isSubmitSuccess ?
          <MessageBox
            message={successMessage}
          />
          :
          <>
            <div className="row admin-profile-update-form justify-content-center">
              <div className="col-lg-12">
                <AlertDismissible
                  message={errorMessage}
                  setErrorMessage={setErrorMessage}
                />
                <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="admin-profile-condition rounded-2 fw-bold">
                        <span className="icon rounded-pill">
                          <FontAwesomeIcon icon={faInfo} />
                        </span>
                        {selectedPaymentMethod == 'Bkash' &&
                          <span className="text">Minimum Deposit Amount <strong>300</strong> &amp; Maximum Amount <strong>25000</strong></span>
                        }
                        {selectedPaymentMethod && (selectedPaymentMethod != 'Bkash') &&
                          <span className="text text-danger">Method Not Available Now.</span>
                        }
                        {!phoneTo &&
                          <span className="text text-danger" >Deposit Not Available</span>
                        }
                        {phoneTo && (!selectedPaymentMethod) &&
                          <span className="text text-warning">Choose a Deposit Method.</span>
                        }
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="adf-deposit-payment-method">Payment Method*</label>

                        {depositPaymentMethodList && depositPaymentMethodList.length > 0 &&
                          <div className="form-group-input-wrapper">
                            <span className="input-group-icon">
                              <FontAwesomeIcon icon={faMoneyBillAlt} />
                            </span>
                            <select className="form-control"
                              {...register("payment_method")}
                              value={selectedPaymentMethod ? selectedPaymentMethod : depositPaymentMethodList[0].id}
                              onChange={(e) => handleSelect(e)}
                            >
                              {depositPaymentMethodList.map((method, index) => {
                                return (
                                  <option value={method.id} key={index}>{method.name}</option>
                                )
                              })}
                            </select>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="adf-deposit-coin">Numbers of Coin*</label>
                        <div className="form-group-input-wrapper">
                          <span className="input-group-icon">
                            <FontAwesomeIcon icon={faCoins} />
                          </span>
                          <input type="number" className="form-control" id="adf-deposit-coin" placeholder="Numbers of Coin"
                            {...register("coin_number", {
                              required: "Coin required",
                              min: {
                                value: 0,
                                message: "Coin must be greater than 0"
                              },
                            })}
                          />
                        </div>
                        {errors.coin_number && <span className="input-validation">{errors.coin_number.message}</span>}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="adf-deposit-phone-from">Phone From*</label>
                        <div className="form-group-input-wrapper">
                          <span className="input-group-icon">
                            <FontAwesomeIcon icon={faPhoneVolume} />
                          </span>
                          <input type="text" className="form-control" id="adf-deposit-phone-from" placeholder="Phone Number, from where you send..."
                            {...register("phone_from", {
                              required: "Phone required",
                              minLength: {
                                value: 11,
                                message: "Phone number must be 11 digit"
                              },
                              maxLength: {
                                value: 11,
                                message: "Phone number must be 11 digit"
                              },
                              pattern: {
                                value: /^[0-9]+$/i,
                                message: 'Please enter a number',
                              },
                            })}
                          />
                        </div>
                        {errors.phone_from && <span className="input-validation">{errors.phone_from.message}</span>}
                      </div>
                    </div>

                    {phoneTo &&
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="adf-deposit-phone-to">Phone To*</label>
                          <div className="form-group-input-wrapper">
                            <span className="input-group-icon">
                              <FontAwesomeIcon icon={faPhoneVolume} />
                            </span>
                            <input type="text" className="form-control" id="adf-deposit-phone-to" placeholder="Phone Number" name="phoneTo" value={phoneTo} readOnly />
                          </div>
                        </div>
                      </div>
                    }

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="adf-current-password-for-club">Current Password*</label>
                        <div className="form-group-input-wrapper">
                          <span className="input-group-icon">
                            <FontAwesomeIcon icon={faKey} />
                          </span>
                          <input
                            className="form-control"
                            type={passwordMode.password}
                            placeholder="Current Password"
                            {...register("password", {
                              required: "Password required",
                            })}
                          />
                          <button type="button" className="field-icon toggle-password bg-transparent border-0 d-flex align-items-center p-0" onClick={() => handlePasswordMode("password")}>
                            <FontAwesomeIcon icon={passwordMode.password == "password" ? faEye : faEyeSlash} />
                          </button>
                        </div>
                        {errors.password && <span className="input-validation" style={{ right: "40px" }}>{errors.password.message}</span>}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <button type="submit" className="admin-profile-update-form-submit" name="admin-make-deposit" disabled={isSubmitting}>Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {isSubmitting &&
              <LoadingSpinner overlay={true} />
            }
          </>
        }
      </div>
    </div>
  </>)
}

export default connect(null, { setMessage, setUpdatedCoinsChange })(MakeDeposit);